import { Controller } from "@hotwired/stimulus"
import IMask from "imask";

export default class extends Controller {
  static values = {
    signed: { type: Boolean, default: false }
  };

  connect() {
    const maskOptions = {
      mask: Number,
      thousandsSeparator: ' ',
      scale: 2,
      radix: ',',
      signed: this.signedValue
    };
    this.mask = IMask(this.element, maskOptions);
  }

  disconnect() {
    this.mask?.destroy();
  }

}

