import { Controller } from "@hotwired/stimulus";
// TODO: #13774
export default class extends Controller {
  static targets = ["cleanable"]
  static values = { active: { type: Boolean } }

  connect() {
    if (this.activeValue) {
      this.waitForTarget().then(() => {
        this.cleanHTML();
      });
    }
  }

  async waitForTarget() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.hasCleanableTarget) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  cleanHTML() {
    let html_to_clean = this.cleanableTarget;
    html_to_clean.querySelectorAll('a:not(.report-visible)').forEach(a => a.remove());
    html_to_clean.querySelectorAll('.clickable-link a').forEach(a => {
      a.replaceWith(...a.childNodes);
    });
    html_to_clean.querySelectorAll('.mb20').forEach(el => {
      el.classList.remove('mb20');
      el.classList.add('mb5');
    });
    html_to_clean.querySelectorAll('.card-header .text-overflow').forEach(el => el.classList.remove('text-overflow'));
    html_to_clean.querySelectorAll('.flex.flex-horizontal-stretch').forEach(el => {
      el.classList.remove('flex', 'flex-center', 'flex-horizontal-stretch');
    });
    html_to_clean.querySelectorAll('.col-lg-6').forEach(el => {
      el.classList.remove('col-xs-12', 'col-lg-6');
      el.classList.add('col-6');
    });
    html_to_clean.querySelectorAll('h5, u, .small-font-size, .text-left').forEach(el => el.classList.remove('col-xs-12'));

    html_to_clean.querySelectorAll('.max-width-40pc').forEach(el => {
      el.classList.remove('max-width-40pc');
      el.classList.add('col-6', 'no-padding-left');
    });
    html_to_clean.querySelectorAll('.max-width-50pc').forEach(el => {
      el.classList.remove('max-width-50pc');
      el.classList.add('col-6', 'no-padding-left');
    });
    html_to_clean.querySelectorAll('.max-width-60pc').forEach(el => {
      el.classList.remove('max-width-60pc');
      el.classList.add('col-6', 'no-padding-left');
    });

    // Tailwind retro compatibility
    let infoCardHeader = this.element.querySelector('#asset-info-card');
    if (infoCardHeader) {
      infoCardHeader.className = 'main-card minhauto h100pc';
      infoCardHeader.querySelector('.p-0_5.px-2_5.bg-white')?.classList.replace('p-0_5', 'card-header', 'plr10px');
      infoCardHeader.querySelector('.d-flex.items-center.justify-between.flex-no-wrap')?.classList.remove('d-flex', 'items-center', 'justify-between', 'flex-no-wrap');
      infoCardHeader.querySelector('.font-medium.text-sm')?.classList.remove('font-medium', 'text-sm');
      infoCardHeader.querySelector('.d-flex.flex-col')?.classList.replace('d-flex', 'row', 'flex-col');
      infoCardHeader.querySelector('.border.border-solid.border-gray.divide-y.my-2')?.classList.replace('border', 'separator', 'mb-2');
      infoCardHeader.querySelector('.p-2_5.bg-white')?.classList.replace('p-2_5', 'card-content', 'p10');
      infoCardHeader.querySelector('span.max-w-60')?.classList.add('float-left', 'col-6', 'no-padding-left');
      let spanMaxWidth40 = infoCardHeader.querySelector('span.max-width-40');

      if (spanMaxWidth40) {
        spanMaxWidth40.className = 'float-right text-right col-6 no-padding-left';
      }
      let spans = infoCardHeader.querySelectorAll('span.w-full.pb-2');

      spans.forEach(span => {
        // Create a new <p> element
        let p = document.createElement('p');

        // Set the content of the <p> element
        p.innerHTML = span.innerHTML;

        // Add the "mt0" class to the <p> element
        p.classList.add('mt0');

        // Replace the <span> with the new <p>
        span.replaceWith(p);
      });
    }

    this.element.querySelectorAll('h5').forEach(h5 => {
      let p = document.createElement('p');
      p.innerHTML = h5.innerHTML;
      p.className = h5.className;
      h5.replaceWith(p);
    });

    for (let i = 1; i <= 12; i++) {
      html_to_clean.querySelectorAll(`.col-xs-${i}`).forEach(el => {
        el.classList.replace(`col-xs-${i}`, `col-${i}`);
      });
    }

    // Trigger resize event to force charts to take 100% width of container
    window.dispatchEvent(new Event('resize'));
  }
}
