// Can be used e.g. on input fields (controls) in a form that change other input fields
// If the control changes the form is submitted with a "draft": true param that can be handled in the controller 
// (e.g. assign, don't save and rerender edit)

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"];

  static values = {
    additionalData: Object,
  }

  connect() {
    if(this.hasSelectTarget){
      this.element.addEventListener("change", this.processChange.bind(this));
      $(this.selectTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event);
      });
    } else {
      this.element.addEventListener("change", this.processChange.bind(this));
    }
  }

  disconnect() {
    this.element.removeEventListener("change", this.processChange.bind(this))
  }

  findForm() {
    let parent = this.element.parentElement;
    while (parent.tagName !== "FORM") parent = parent.parentElement;
    return parent;
  }

  draftInputNode() {
    let draftInputNode = document.createElement('input')
    draftInputNode.type= "hidden"
    draftInputNode.name= "draft"
    draftInputNode.value= this.element.getAttribute("data-draft") || "true"
    return draftInputNode
  }

  submitButtonNode() {
    let submitButtonNode = document.createElement('button')
    submitButtonNode.type = "submit"
    submitButtonNode.style.display = "none"
    submitButtonNode.formNoValidate = true
    if (this.element.dataset['turboFrame']) submitButtonNode.dataset['turboFrame'] = this.element.dataset['turboFrame']
    return submitButtonNode
  }

  processChange() {
    Object.entries(this.additionalDataValue ?? {}).forEach(([k, v]) => {
      let input = document.createElement('input')
      input.type = "hidden"
      input.name = k
      input.value = v
      this.element.parentNode.insertBefore(input, this.element)
    })
    this.element.parentNode.insertBefore(this.draftInputNode(), this.element)
    const submitButtonNode = this.submitButtonNode()
    this.element.parentNode.insertBefore(submitButtonNode, this.element)
    submitButtonNode.click()
  }
}
