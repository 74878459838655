// app/javascript/controllers/tooltip_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.createTooltip();
  }

  disconnect() {
    this.destroyTooltip();
  }

  createTooltip() {
    this.element.addEventListener('mouseenter', this.showTooltip.bind(this));
    this.element.addEventListener('mouseleave', this.hideTooltip.bind(this));
  }

  showTooltip() {
    const tooltipContent = this.element.dataset.tooltipInnerHtml || this.element.dataset.title;
    const placement = this.element.dataset.placement || 'top';

    if (!tooltipContent ) return;


    const elementRect = this.element.getBoundingClientRect();

    this.ghostElement = document.createElement('div');
    this.ghostElement.className = 'z-300 absolute';
    this.ghostElement.style.top = `${elementRect.top}px`;
    this.ghostElement.style.left = `${elementRect.left}px`;
    this.ghostElement.style.width = `${elementRect.width}px`;
    this.ghostElement.style.height = `${elementRect.height}px`;
    this.ghostElement.style.pointerEvents = 'none';
    document.body.appendChild(this.ghostElement);

    const tooltipContainer = document.createElement('div');
    tooltipContainer.className = 'z-300 absolute flex items-center';
    this.ghostElement.appendChild(tooltipContainer);
    
    const tooltipElement = document.createElement('div');
    tooltipElement.className = 'z-300 bg-black text-white text-sm p-1 rounded bs-override overflow-auto';
    if (this.element.dataset.tooltipInnerHtml != null) tooltipElement.innerHTML = this.element.dataset.tooltipInnerHtml;
    if (this.element.dataset.title != null) tooltipElement.textContent = this.element.dataset.title;
    tooltipContainer.appendChild(tooltipElement);

    const arrowElement = document.createElement('div');
    arrowElement.className = 'z-300 bg-black w-2_5 h-2_5 rotate-45 bs-override';
    tooltipContainer.appendChild(arrowElement);

    const availableSpaceLeft = elementRect.left
    const availableSpaceRight = window.innerWidth - elementRect.right
    const windowBorderPadding = 8;

    if (placement === 'top' || placement === 'bottom') {
      tooltipContainer.style.width = `${elementRect.width + ((Math.min(availableSpaceLeft, availableSpaceRight) - windowBorderPadding) * 2)}px`;
      tooltipContainer.style.left = '50%';
      tooltipContainer.style.transform = 'translateX(-50%)';
      tooltipContainer.style.flexDirection = placement === 'top' ? 'column' : 'column-reverse';
      tooltipContainer.style[placement === 'top' ? 'bottom' : 'top'] = 'calc(100% + 5px)';
      arrowElement.style[placement === 'top' ? 'marginTop' : 'marginBottom'] = '-5px'
    } else {
      tooltipContainer.style.width = placement === 'left'
        ? `${availableSpaceLeft - windowBorderPadding}px`
        : `${availableSpaceRight - windowBorderPadding}px`;
      tooltipContainer.style.top = '50%';
      tooltipContainer.style.transform = 'translateY(-50%)';
      tooltipContainer.style.flexDirection = placement === 'left' ? 'row' : 'row-reverse';
      tooltipContainer.style[placement === 'left' ? 'right' : 'left'] = 'calc(100% + 5px)';
      arrowElement.style[placement === 'left' ? 'marginLeft' : 'marginRight'] = '-5px'
      if (placement === 'left') tooltipContainer.style.justifyContent = 'end';
    }
  }

  hideTooltip() {
    this.ghostElement?.remove()
  }

  destroyTooltip() {
    this.hideTooltip();
    this.element.removeEventListener('mouseenter', this.showTooltip);
    this.element.removeEventListener('mouseleave', this.hideTooltip);
  }
}
